import "./Services.css";
// import {GrDocumentPerformance} from "react-icons/gr"
import AcUnitIcon from '@mui/icons-material/AcUnitOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UpdateIcon from '@mui/icons-material/Update';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SecurityIcon from '@mui/icons-material/Security';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PsychologyIcon from '@mui/icons-material/Psychology';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
function Services() {
  return (
    <>
      <h1 className="heading1" style={{textAlign:"center",marginTop:"2%"}}>Detailed List why to reasons to opt for using this online service</h1>
            <div className="service-container" >
              
            <div  className="service-col">
                <section>
                    <TrendingUpIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Efficiency</h1>
                </section>
                <p  style={{textAlign:"center"}}>It streamlines the process of allocating vehicles to clients, saving you time and effort.
                </p>
            </div>
            <div  className="service-col">
                <section>
                    <AcUnitIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Versatility</h1>
                </section>
                <p  style={{textAlign:"center"}}>It can be used for various transportation needs, including buses, shuttles, and VIP services.
                </p>
            </div>
            <div className="service-col">
                <section>
                    <BeenhereIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Reliability</h1>
                </section>
                <p  style={{textAlign:"center"}}>It can be used for various transportation needs, including buses, shuttles, and VIP services.
                </p>
            </div>
            <div  className="service-col">
                <section>
                    <AccessibilityIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Simplicity</h1>
                </section>
                <p  style={{textAlign:"center"}}>Simplify the reservation process with an intuitive online tool.
                </p>
            </div>
            <div className="service-col">
                <section>
                    <TaskAltIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Convenience</h1>
                </section>
                <p  style={{textAlign:"center"}}>Access and manage vehicle allocations online, making it convenient and accessible.
                </p>
            </div>
            <div  className="service-col">
                <section>
                    <UpdateIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Time-Saving</h1>
                </section>
                <p  style={{textAlign:"center"}}>Save time on administrative tasks, allowing you to focus on other aspects of your business.
                </p>
            </div>
            <div className="service-col">
                <section>
                    <MiscellaneousServicesIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Enhanced Service</h1>
                </section>
                <p  style={{textAlign:"center"}}>Provide better service to your clients by ensuring that their transportation needs are met efficiently.
                </p>
            </div>
            <div  className="service-col">
                <section>
                    <MyLocationIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Accuracy</h1>
                </section>
                <p  style={{textAlign:"center"}}>Reduce the risk of errors in vehicle allocation.
                </p>
            </div>
            <div className="service-col">
                <section>
                    <AttachMoneyIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Cost-Effective</h1>
                </section>
                <p  style={{textAlign:"center"}}>Optimize vehicle usage, potentially saving on operational costs.
                </p>
            </div>
            <div className="service-col">
                <section>
                    <PsychologyIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Improved Planning</h1>
                </section>
                <p  style={{textAlign:"center"}}>Better plan and coordinate transportation services for your clients.
                </p>
            </div>
            <div className="service-col">
                <section>
                    <SecurityIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >Enhanced Security</h1>
                </section>
                <p  style={{textAlign:"center"}}>Minimize the risk of system infections or data breaches by using a secure online platform.
                </p>
            </div>
            <div className="service-col">
                <section>
                    <FileDownloadOffIcon className="icons"/>
                    <h1 className="heading2"  style={{textAlign:"center"}} >No Installation Required</h1>
                </section>
                <p  style={{textAlign:"center"}}>Access the service without the need to install any software, reducing setup hassles.
                </p>
            </div>
        </div>
    </>);}
export default Services