import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";
import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import FlightIcon from "@mui/icons-material/Flight";
import SettingsIcon from "@mui/icons-material/Settings";

// interface SidebarProps {
//   appHeight: number;
// }
function Sidebar() {
  //   const [sidebarHeight, setSidebarHeight] = React.useState<number>(appHeight);

  //   React.useEffect(() => {
  //     const handleResize = () => {
  //       setSidebarHeight(appHeight);
  //     };

  //     window.addEventListener('resize', handleResize);

  //     return () => {
  //       window.removeEventListener('resize', handleResize);
  //     };
  //   }, [appHeight]);

  const navigate = useNavigate();

  const toDashboard = () => {
    navigate("/dashboard");
  };

  const toProfile = () => {
    navigate("/profile");
  };

  const toPassegeners = () => {
    navigate("/passengers");
  };

  const toScheduling = () => {
    navigate("/scheduling");
  };

  const toOptimization = () => {
    navigate("/optimization");
  };

  const toMonitoring = () => {
    navigate("/flight-monitoring");
  };

  const toPreferences = () => {
    navigate("/preferences");
  };

  return (
    <>
      <div style={{ background: "white" }} className="side_menu">
        <ul className="main_menu">
          <li onClick={toDashboard}>
            <DashboardIcon style={{ fontSize: "1rem" }} />
            <Link to="/dashboard">
              <span>Dashboard</span>
            </Link>
          </li>
          <li onClick={toProfile}>
            <PersonIcon style={{ fontSize: "1rem" }} />
            <Link to="/profile">
              <span>Profile</span>
            </Link>
          </li>
          <li onClick={toPassegeners}>
            <EmojiPeopleIcon style={{ fontSize: "1rem" }} />
            <Link to="/passengers">
              <span>Passengers</span>
            </Link>
          </li>
          <li onClick={toScheduling}>
            <DateRangeIcon style={{ fontSize: "1rem" }} />
            <Link to="/scheduling">
              <span>Scheduling</span>
            </Link>
          </li>
          <li onClick={toOptimization}>
            <SettingsSuggestIcon style={{ fontSize: "1rem" }} />
            <Link to="optimization">
              <span>Optimiztion</span>
            </Link>
          </li>
          <li onClick={toMonitoring}>
            <FlightIcon style={{ fontSize: "1rem" }} />
            <Link to="flight-monitoring">
              <span>Flight Monitoring</span>
            </Link>
          </li>
          <li onClick={toPreferences}>
            <SettingsIcon style={{ fontSize: "1rem" }} />
            <Link to="/preferences">
              <span>Preferences</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
