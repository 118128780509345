import CustomizedMenus from "./uploadButton";
import "./uploadFile.css";
import { BsFiletypeCsv } from "react-icons/bs";
// import {AiOutlineSetting} from "react-icons/ai"
import { CgDetailsMore } from "react-icons/cg";
import img from "../assets/bg_img.svg";
import React from "react";

function UploadFile() {
  const [showDiv, setShowDiv] = React.useState(false);

  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };

  const [count, setCount] = React.useState(3);

  //  const [inputValue, setInputValue] = React.useState<string>('');

  //  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //    setInputValue(event.target.value);
  //  };

  //  const handleSubmit = (event: React.FormEvent<HTMLInputElement>) => {
  //    event.preventDefault();
  //    console.log('Submitted input value:', inputValue);
  //  };

  //  const handleButtonClick = (event: React.FormEvent<HTMLInputElement>) => {
  //     handleSubmit(event);
  //     toggleDiv();
  //   };

  return (
    <>
      <div>
        <div className="heading-container">
          <h1 className="heading1">
            Effortlessly Allocate Your Vehicles Online
          </h1>
          <h2 className="heading3" style={{ marginBottom: "4%" }}>
            With our intuitive online Vehicle Allocator, streamline vehicle
            assignments for all transportation needs, whether it's buses,
            shuttles, or even VIP services. Simplify reservations, save time,
            and ensure reliable transfers.
          </h2>
        </div>
        <section className="upload-container">
          <BsFiletypeCsv style={{ fontSize: "50px", color: "white" }} />
          <CustomizedMenus />
          <div className="heading3" style={{ color: "white" }}>
            or drop files here
          </div>
        </section>
        <div className="card">
          <div className="card-header heading4">
            <CgDetailsMore />
            Optional Details
          </div>
          <div className="form-group">
            <label className="no-margin">
              How many buses/fleets do you own?
            </label>
            <input
              type="text"
              placeholder="Enter a number"
              className="bus_input"
              required
            />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {showDiv ? (
              Array.from({ length: count }).map((_, index) => (
                <form className="bus-form">
                  <h3
                    className="heading4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Bus {index + 1}
                  </h3>
                  <div className="form-group-one">
                    <label className="heading5">Bus Number</label>
                    <input
                      type="text"
                      placeholder="Enter bus number"
                      className="bus_input"
                    />
                  </div>
                  <div className="form-group-one">
                    <label className="heading5">Bus Capacity</label>
                    <input
                      type="text"
                      placeholder="Enter capacity"
                      className="bus_input"
                    />
                  </div>
                </form>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>

        <button className="start_btn" onClick={toggleDiv}>
          Submit
        </button>
        <div
          className="choice-container"
          style={{ backgroundImage: `url(${img})` }}
        >
          <div style={{ margin: "3% 8% 5% 8%" }}>
            <h1 className="heading1">
              Why should you choose this online service?
            </h1>
            <ol className="heading3">
              <li style={{ marginBottom: "15px" }}>
                Choosing our online TransferTools streamlines vehicle
                assignments. It's a versatile, time-saving solution for various
                transport needs, from buses to VIP services. Meet clients'
                demands efficiently for organized, reliable transfers.
              </li>
              <li style={{ marginBottom: "15px" }}>
                Our service boasts an intuitive interface that simplifies the
                reservation process, making it straightforward and
                user-friendly. Streamlining reservations with internet
                accessibility from anywhere. Skip complex installations,
                ensuring system security, reducing infection risks, and enjoy
                remote flexibility.
              </li>
              <li style={{ marginBottom: "15px" }}>
                By utilizing our online TransferTools, an effective setup that
                optimizes vehicle usage, potentially leading to cost savings in
                operational expenses. With this service, you can better plan and
                coordinate transportation services, ensuring that your clients'
                needs are met efficiently and reliably.
              </li>
              <li style={{ marginBottom: "15px" }}>
                Our allocation system transforms your reservation list into a
                dependable and efficient transfer solution. Whether you're
                overseeing transportation for large groups, coordinating shuttle
                services, or arranging VIP transfers, our system guarantees
                efficiency and reliability.
              </li>
              <li style={{ marginBottom: "15px" }}>
                Unlike traditional methods that assign dedicated vehicles to
                individual passengers, we prioritize flexibility. Passengers can
                be grouped either chronologically, ensuring seamless transfers
                for groups traveling at similar times, or destination-based,
                optimizing routes to minimize travel time. Say goodbye to the
                complexities of traditional allocation methods and embrace our
                user-friendly system.
              </li>
              <li>
                Streamline Your Transfers (which you like most): Our allocation
                system simplifies your transfer management. Ideal for handling
                large groups, shuttle services, or VIP transfers, it offers
                efficiency and reliability. Unlike traditional methods, we
                prioritize flexibility. Passengers can be grouped
                chronologically for seamless transfers or destination-based to
                optimize routes and save time. Simplify your transfer process
                with us.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadFile;
