import Header from "../components/Header";
import Services from "../components/Services";
import UploadFile from "../components/uploadFile";

function Home() {
    return (
        <>
        <Header/>
        <UploadFile/>
        <Services/>
        </>
        );
}

export default Home;