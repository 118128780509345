import "./Dashboard.css";
import { CgDetailsMore } from "react-icons/cg";
import Layout from "./Layout";
function Dashboard() {
  return (
    <Layout>
      <div className="dashboard">
        <h6 className="dashboard_subheading">Dashboard {">"} Home</h6>
        <h4 className="dashboard_heading">Dashboard</h4>
        <h6 className="heading3" style={{ width: "65%" }}>
          Welcome to the user dashboard! Here you can see your user information
          as well as edit it, change your password and e-mail address, and more.
        </h6>
        <div className="card-dash">
          <div className="card-header-dash heading4">Your Account</div>
          <div className="form-group-dash">
            <p className="heading4" style={{ fontWeight: "600" }}>
              Username
            </p>
            <p className="heading4">user_5523</p>
          </div>
          <div className="form-group-dash">
            <p className="heading4" style={{ fontWeight: "600" }}>
              Email
            </p>
            <p className="heading4">johnsmith@gmail.com</p>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}></div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
