import React from 'react';
import './App.css';
import Home from './pages/Home';
import Profile from './pages/Profile';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Passengers from './pages/Passengers';
function App() {
  
  // const [appHeight, setAppHeight] = React.useState<number>(window.innerHeight);

  // React.useEffect(() => {
    // Update the sidebar height when the window is resized
    // const handleResize = () => {
    //   setAppHeight(window.innerHeight);
    // };

    // Attach the event listener
    // window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home/>}></Route> 
      <Route path="/dashboard" element={<Dashboard/>}></Route>
      <Route path="/profile" element={<Profile/>}> </Route>
      <Route path="/passengers" element={<Passengers/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
