import * as React from "react";
import { useNavigate } from 'react-router-dom';
// import {Link }from "@mui/material";
import "./accountMenu.css"
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Divider } from "@mui/material";
import ReceiptIcon from '@mui/icons-material/Receipt';
export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const toDashboard = () => {
        navigate('/dashboard')
     }
  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
         <ArrowDropDownIcon/>
        </IconButton>
      </Tooltip>

      <Menu 
        style={{color:"white"}}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: 1,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={toDashboard} className="menu-item">
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon >
          Dashboard
        </MenuItem>
        <Divider className="divider_"/>
        <MenuItem onClick={handleClose} className="menu-item">
          <ListItemIcon>
            <ReceiptIcon fontSize="small" />
          </ListItemIcon>
         Invoice
        </MenuItem>
        <Divider className="divider_"/>
        <MenuItem onClick={handleClose} className="menu-item">
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
