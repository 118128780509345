import React from "react";
import { FaAngleRight, FaEllipsisH } from "react-icons/fa";
import "./Passengers.css";
import Layout from "./Layout";
function Passengers() {
  const fleets = [
    {
      id: 1,
      bus_plate: "ABC124",
      date: "2023-07-01",
      bus_driver: "111",
      pickup_location: "Rawalpindi Airport",
      destination: "Marriat Hotel",
      flight_status: "Late",
      isDropdownOpen: false,
    },
    {
      id: 2,
      bus_plate: "FIJ123",
      date: "2023-07-02",
      bus_driver: "111",
      pickup_location: "Islamabad Airport",
      destination: "Marriat Hotel",
      flight_status: "On Time",
      isDropdownOpen: false,
    },
    {
      id: 3,
      bus_plate: "CDE123",
      date: "2023-07-03",
      bus_driver: "111",
      pickup_location: "Islamabad Airport",
      destination: "Marriat Hotel",
      flight_status: "On Time",
      isDropdownOpen: false,
    },
    {
      id: 4,
      bus_plate: "RDG123",
      date: "2023-07-04",
      bus_driver: "111",
      pickup_location: "Islamabad Airport",
      destination: "Marriat Hotel",
      flight_status: "Late",
      isDropdownOpen: false,
    },
    {
      id: 5,
      bus_plate: "RDG123",
      date: "2023-07-04",
      bus_driver: "111",
      pickup_location: "Multan Airport",
      destination: "Marriat Hotel",
      flight_status: "On Time",
      isDropdownOpen: false,
    },
    {
      id: 6,
      bus_plate: "RDG123",
      date: "2023-07-07",
      bus_driver: "111",
      pickup_location: "Karachi Airport",
      destination: "Marriat Hotel",
      flight_status: "On Time",
      isDropdownOpen: false,
    },
    {
      id: 7,
      bus_plate: "RDG123",
      date: "2023-07-07",
      bus_driver: "111",
      pickup_location: "Karachi Airport",
      destination: "Marriat Hotel",
      flight_status: "On Time",
      isDropdownOpen: false,
    },
    {
      id: 8,
      bus_plate: "RDG123",
      date: "2023-07-08",
      bus_driver: "111",
      pickup_location: "Karachi Airport",
      destination: "Marriat Hotel",
      flight_status: "On Time",
      isDropdownOpen: false,
    },
    {
      id: 9,
      bus_plate: "RDG123",
      date: "2023-07-09",
      bus_driver: "111",
      pickup_location: "Karachi Airport",
      destination: "Marriat Hotel",
      flight_status: "On Time",
      isDropdownOpen: false,
    },
  ];

  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleDotClick = (index: any) => {
    setSelectedRow(index === selectedRow ? null : index);
  };
  return (
    <Layout>
      <div className="dashboard">
        <h6 className="dashboard_subheading">Dashboard {">"} Passengers</h6>
        <h4 className="dashboard_heading">Passengers</h4>
        <div className="div_container">
        <h4 className='fleet_titles'><FaAngleRight className="angle_right"/>All Passengers</h4>
          <div className="today_fleets">
            <div className="header_th">
              <div>Name</div>
              <div>Pax</div>
              <div>Flight #</div>
              <div>Arrival</div>
              <div>Destination</div>
              <div>Departure Time</div>
              <div>&nbsp;</div>
            </div>
            <div>
              {fleets.map((fleet, index) => (
                <div className="header_td" key={index}>
                  <div>{fleet.bus_plate}</div>
                  <div>{fleet.date}</div>
                  <div>{fleet.bus_driver}</div>
                  <div>{fleet.pickup_location}</div>
                  <div>{fleet.destination}</div>
                  <div>{fleet.flight_status}</div>
                  <div>
                    <div
                      className="edit_menu"
                      onClick={() => handleDotClick(index)}
                    >
                      <FaEllipsisH className="edit_dots" />
                    </div>
                    {selectedRow === index && (
                      <ul className="edit_menu_ul" v-if="fleet.isDropdownOpen">
                        <li>
                          <a href="">Open</a>
                        </li>
                        <li>
                          <a href="">Edit</a>
                        </li>
                        <li>
                          <a href="">Delete</a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="upcoming_fleets"></div>
        </div>
      </div>
    </Layout>
  );
}

export default Passengers;
