import "./Header.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { BsBorderAll } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { BsGlobe } from "react-icons/bs";
import { BsFillBusFrontFill } from "react-icons/bs";
import { BiLogIn } from "react-icons/bi";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { FcGoogle } from "react-icons/fc";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { Link } from "react-router-dom";
import AccountMenu from "./accountMenu";
import { Avatar } from "@mui/material";
const style = {
  transform: "translate(9%, 0%)",
  background: "#f5f5f5",
  padding: "30px",
  width: "90%",
  height: "100%",
  overflowX:"auto",
};

function Header() {
  //For Login Modal
  const [openLogin,setOpenLogin]=React.useState(false);
  const handleOpenLogin = () =>{ setOpenLogin(true);setOpen(false)};
  const handleCloseLogin = () => setOpenLogin(false);

  //For Signup Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {setOpen(true);setOpenLogin(false)};
  const handleClose = () => setOpen(false);
 
  return (
    <>
      <nav className="header">
        <div className="logo_div">
         <Link to='/'><DirectionsBusIcon className="temp" /></Link> 
          <span className="heading4">Fleet CMS</span>
        </div>
        <ul
          style={{
            display: "flex",
            listStyle: "none",
            gap: "2%",
            width: "100%",
            
          }}
        >
          <li className="heading4">
            <a style={{ display: "flex", alignItems: "center" }}>
              <BsBorderAll />
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                All Tools
              </span>
              <RiArrowDropDownLine style={{ fontSize: "24px" }} />
            </a>
          </li>
          <li className="d-none font-weight-bold heading3">
            <a>Edit Pdf</a>
          </li>
          <li className="d-none font-weight-bold heading3">
            <a>Compress Pdf</a>
          </li>
          <li className="d-none font-weight-bold heading3">
            <a>Split Pdf</a>
          </li>
        </ul>
        <div className="icons_left">
          <ul
            style={{
              display: "flex",
              listStyle: "none",
              gap: "25%",
              width: "100%",
            }}
          >
            <li className="heading4">
              <a title="Pricing">Pricing</a>
            </li>
            <li className="heading4">
              <a title="Help">Help</a>
            </li>
          </ul>
          <div className="d-flex">
            {/* <a>
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                EN
              </span>
              <BsGlobe/>
            </a> */}
            {/* <button onClick={handleOpenLogin} className="heading4" style={{ display: "flex", alignItems: "center",background:"transparent",outline:"none",border:"none" }}>
              <BiLogIn />
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                Login
              </span>
            </button> */}
            {/* Login Modal */}
            <Modal open={openLogin} onClose={handleCloseLogin}>
            <Box sx={style}>
              <div className="clear_icon">
                <ClearIcon onClick={handleCloseLogin} />
              </div>
              <div className="center_div">
                <h1
                  className="heading1"
                  style={{ textAlign: "center", marginBottom: "2rem" }}
                >
                  Login
                </h1>
                <button className="google_btn">
                  <FcGoogle style={{fontSize:"1.5rem"}} />
                  <span>Continue with google</span>
                </button>
                <p className="heading4" style={{ marginBottom: "2rem" }}>
                  or
                </p>
                <div className="email_group">
                  <MailOutlineIcon />
                  <input
                    type="text"
                    placeholder="Enter your email"
                    className="email_input"
                  />
                </div>
                <div className="email_group">
                  <HttpsOutlinedIcon />
                  <input
                    type="text"
                    placeholder="Enter your password"
                    className="email_input"
                  />
                </div>
                <button className="register_btn">
                  <BiLogIn style={{color:"white",fontSize:"1.3rem"}} />
                  <span>Login</span>
                </button>
                <p className="heading4" style={{ marginBottom: "2rem" }}>
                  <a href="/register" style={{ textDecoration: "none" }}>
                      <span
                        style={{
                          color: "#3c70b3",
                          fontWeight: "600",
                        }}
                      >
                        Forgot your password?
                      </span>
                    </a>
                </p>
                <p className="heading4" style={{ marginBottom: "2rem" }}>
                  Don't have an account yet? {""}
                  <button onClick={handleOpen} style={{ textDecoration: "none",background:"transparent",outline:"none",border:"none" }}>
                      <span
                        style={{
                          color: "#3c70b3",
                          fontWeight: "600",
                        }}
                      >
                        Sign up
                      </span>
                    </button>
                </p>
                <br/><br/><br/><br/>
              </div>
            </Box>
          </Modal>
          </div>
          {/* <button onClick={handleOpen} className="signup_btn">
            Sign up
          </button> */}
          {/* Sign up Modal */}
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <div className="clear_icon">
                <ClearIcon onClick={handleClose} />
              </div>
              <div className="center_div">
                <h1
                  className="heading1"
                  style={{ textAlign: "center", marginBottom: "2rem" }}
                >
                  Create a new account
                </h1>
                <button className="google_btn">
                  <FcGoogle style={{fontSize:"1.5rem"}} />
                  <span>Sign up with google</span>
                </button>
                <p className="heading4" style={{ marginBottom: "2rem" }}>
                  or sign up using your email address
                </p>
                <div className="email_group">
                  <MailOutlineIcon />
                  <input
                    type="text"
                    placeholder="Email"
                    className="email_input"
                  />
                </div>
                <div className="email_group">
                  <HttpsOutlinedIcon />
                  <input
                    type="text"
                    placeholder="Password"
                    className="email_input"
                  />
                </div>
                <div className="accept_terms">
                  <input type="checkbox" id="agree terms" name="agree terms" />
                  <label className="text_checkbox heading4">
                    I accept the{" "}
                    <a href="/register" style={{ textDecoration: "none" }}>
                      <span
                        style={{
                          color: "#3c70b3",
                          fontWeight: "600",
                        }}
                      >
                        Terms of Use{" "}
                      </span>
                    </a>
                    and the{" "}
                    <a href="/register" style={{ textDecoration: "none" }}>
                      <span
                        style={{
                          color: "#3c70b3",
                          fontWeight: "600",
                        }}
                      >
                        Privacy Policy
                      </span>
                    </a>
                  </label>
                </div>
                <button className="register_btn">
                  <PersonAddAltIcon style={{color:"white"}} />
                  <span>Sign up</span>
                </button>
                <p className="heading4" style={{ marginBottom: "2rem" }}>
                  Already have an acount? {""}
                  <button onClick={handleOpenLogin} style={{ textDecoration: "none",background:"transparent",outline:"none",border:"none" }}>
                      <span
                        style={{
                          color: "#3c70b3",
                          fontWeight: "600",
                        }}
                      >
                        Login
                      </span>
                    </button>
                </p>
                <br/>
                <br/>
                <br/>
                <br/>
              </div>
            </Box>
          </Modal>
          <div style={{display:"flex", gap:"10px",width: "365px",alignItems:"center" }}>
          <Avatar sx={{ width: 34, height: 34 }}>JS</Avatar>  
          <p className="heading4" >
            John Smith
          </p>
          <AccountMenu/>
        </div>
    
        </div>
      </nav>
    </>
  );
}

export default Header;
