import "./Profile.css";
import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";
import Layout from "./Layout";
function Profile() {
  return (
    <Layout>
    <div className="dashboard">
    
        <h6 className="dashboard_subheading">Dashboard {">"} Profile</h6>
        <h4 className="dashboard_heading">Profile</h4>
      

      {/* Profile form */}
      <div className="card-pro">
        <div className="form-group-pro">
          <label className="heading4">Country</label>
          <input
            type="text"
            placeholder="Select your country"
            className="input-pro"
          />
          <label className="heading4">Timezone</label>
          <input
            type="text"
            placeholder="Select your timezone"
            className="input-pro  form-mb-15"
          />
          <input
            type="text"
            placeholder="Name"
            className="input-pro  form-mb-15"
          />
          <input
            type="text"
            placeholder="Phone Number"
            className="input-pro  form-mb-15"
          />
          <button className="save_btn">
            <SaveIcon style={{ fontSize: "1.1rem" }} />
            <span>Save</span>
          </button>
        </div>
      </div>

      {/*Change email  */}
      <h4 className="dashboard_heading">Change Email</h4>
      <div className="card-pro">
        <div className="form-group-pro">
          <label className="heading4">*New Email</label>
          <input type="text" className="input-pro" required />
          <label className="heading4">*Retype Email</label>
          <input type="text" className="input-pro  form-mb-15" required />
          <label className="heading4">*Current Password</label>
          <input type="text" className="input-pro  form-mb-15" required />
          <button className="save_btn">
            <PersonIcon style={{ fontSize: "1.1rem" }} />
            <span>Submit</span>
          </button>
        </div>
      </div>

      {/* Change Password Form  */}
      <h4 className="dashboard_heading">Change Password</h4>
      <div className="card-pro">
        <div className="form-group-pro">
          <label className="heading4">*New Password</label>
          <input type="text" className="input-pro" required />
          <label className="heading4">*Retype New Password</label>
          <input type="text" className="input-pro  form-mb-15" required />
          <label className="heading4">*Current Password</label>
          <input type="text" className="input-pro  form-mb-15" required />
          <button className="save_btn">
            <PersonIcon style={{ fontSize: "1.1rem" }} />
            <span>Submit</span>
          </button>
        </div>
      </div>

      
    </div>
    </Layout>
  );
}

export default Profile;
