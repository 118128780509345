import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import React, { ReactNode } from 'react';
interface LayoutProps {
    children: ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) =>{
    return (
        <>
            <Header />
            <Sidebar />
            <>{children}</>
        </>
    );
}

export default Layout